import request from "@/common/request"

//表单配置管理分页展示
export const formconfigPage = (queryForm) => {
    return request("get", "/form/formConfig/page", queryForm);
}
//表单配置管理列表展示
export const formconfigList = (queryForm) => {
    return request("get", "/form/formConfig/list", queryForm);
}
//表单配置管理详情
export const formconfigInfo = (id) => {
    return request('get', '/form/formConfig/info', {
        id: id
    })
}
//表单配置管理信息保存
export const formconfigSave = (data) => {
    return request('post', '/form/formConfig/save', data)
}
//表单配置管理信息修改
export const formconfigEdit = (data) => {
    return request('post', '/form/formConfig/edit', data)
}
//表单配置管理信息删除
export const formconfigDel = (id) => {
    return request('post', '/form/formConfig/delete?id=' + id)
}
//表单配置管理批量删除
export const formconfigDelBatch = (ids) => {
    return request('post', '/form/formConfig/deleteBatch', ids)
}
//表单配置管理动态表头导出
export const formconfigDynamicExport = (data) => {
    return request("eptpost", '/form/formConfig/exportDynamic', data)
}
//表单配置管理-表单设计修改
export const editDesign = (data) => {
    return request('post', '/form/formConfig/editDesign', data)
}
//表格配置
export const gridManage = (data) => {
    return request('post', '/form/formConfig/editTableDesign', data)
}

//表单配置访问状态修改
export const fcStatusChange = (id) => {
    return request('get', '/form/formConfig/statusChange', {
        id: id
    })
}
